import * as React from "react";
import { initFormatAmount } from "../../core/utils/format";

export interface RangeFieldProps {
  minLabel: string;
  maxLabel: string;
  id?: string;
  value?: string;
  autoComplete?: string;
  step?: number;
  min?: number;
  max?: number;
  required?: boolean;
  onChange: (value: string) => void;
}

const RangeField = ({
  minLabel,
  maxLabel,
  id = "range",
  autoComplete = "number",
  value,
  step = 1,
  min = 0,
  max,
  required,
  onChange,
}: RangeFieldProps) => {
  return (
    <div className="space-y-1">
      <label
        htmlFor="step"
        className="flex justify-between text-sm font-medium text-gray-900"
      >
        <span>{minLabel}</span>
        <span>{maxLabel}</span>
      </label>
      <input
        id={id}
        name={id}
        type="range"
        min={min}
        max={max}
        value={value ? value : min}
        step={step}
        onChange={(e) => onChange(e.target.value)}
        required={required}
        className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-6 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
      />
    </div>
  );
};

export default RangeField;
