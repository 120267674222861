import { Link, navigate } from "gatsby";
import * as React from "react";
import {
  buttonClassNames,
  buttonExtraClassNames,
} from "../../../core/ui/classNames";

export interface LinkButtonProps {
  children: React.ReactNode;
  to: string;
  state?: any;
  isExternal?: boolean;
  isOutline?: boolean;
  isFullWidth?: boolean;
  disabled?: boolean;
}

const LinkButton = ({
  to,
  children,
  state,
  isExternal = false,
  isOutline = false,
  isFullWidth = false,
  disabled = false,
}: LinkButtonProps) => {
  const onLinkClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    e.preventDefault();
    navigate(to, { state });
  };

  const extraClassNames = buttonExtraClassNames(isOutline);
  const classNames = `${extraClassNames} ${buttonClassNames} ${
    isFullWidth ? "w-full justify-center" : ""
  }`;

  if (isExternal) {
    return (
      <a
        href={to}
        onClick={(e) => {
          if (disabled) {
            e.stopPropagation();
            e.preventDefault();
          }
        }}
        target="_blank"
        rel="noreferrer"
      >
        <button
          type="button"
          disabled={disabled}
          onClick={onLinkClick}
          className={classNames}
        >
          {children}
        </button>
      </a>
    );
  } else {
    return (
      <Link to={to}>
        <button
          type="button"
          disabled={disabled}
          onClick={onLinkClick}
          className={classNames}
        >
          {children}
        </button>
      </Link>
    );
  }
};

export default LinkButton;
