export function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

export const buttonExtraClassNames = (isOutline: boolean) => {
  let extraClassNames = "transition duration-200";
  if (isOutline) {
    extraClassNames = `${extraClassNames} bg-white hover:bg-indigo-200 border-indigo-200 text-gray-700`;
  } else {
    extraClassNames = `${extraClassNames} bg-indigo-600 hover:bg-indigo-700 border-transparent text-white shadow-sm hover:shadow-md`;
  }
  return extraClassNames;
};

export const buttonClassNames =
  "inline-flex items-center rounded-md border px-4 py-2 text-sm font-medium focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2";

export const linkClassNames =
  "text-md font-medium leading-4 text-gray-500 underline decoration-indigo-700 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2";
