import React from "react";

const TermsArticle = () => {
  return (
    <article className="prose my-24 px-4 md:px-6 lg:mx-auto lg:max-w-7xl lg:px-8">
      <h1>Terms of Service</h1>
      <h2>MyMicroFinance Demo (Pty) Ltd</h2>
      <h4>
        1. TERMS OF USE OF THE MyMicroFinance Demo (Pty) Ltd WEBSITE/APP
        AGREEMENT
      </h4>
      <p>
        MyMicroFinance Demo (Pty) Ltd and its affiliates (suppliers and service
        providers) provide their services to you subject to the conditions
        detailed in this agreement. By using the MyMicroFinance Demo (Pty) Ltd
        website/app and/or registering to shop on MyMicroFinance Demo (Pty) Ltd
        you confirm that you have familiarized yourself with the terms and
        conditions governing the conduct of parties accessing or using the Nora
        Finance (Pty) Ltd website/app to trade and that you agree to be bound by
        these terms and conditions. Your access to the MyMicroFinance Demo (Pty)
        Ltd website/app and use of any services provided by MyMicroFinance Demo
        (Pty) Ltd including any purchases made by you from MyMicroFinance Demo
        (Pty) Ltd are subject to terms and conditions established under the
        following headings.
      </p>
      <ul>
        <li>Terms of Use of MyMicroFinance Demo (Pty) Ltd Website/app</li>
        <li>Terms of Trade with MyMicroFinance Demo (Pty) Ltd</li>
        <li>Privacy and Security Statement</li>
        <li>Intellectual Property Statement</li>
        <li>Dispute Resolution</li>
        <li>Compliance with statutory consumer protection provisions</li>
      </ul>
      <p>
        <strong>
          Terms of Use of MyMicroFinance Demo (Pty) Ltd Website/app
        </strong>
      </p>
      <p>
        MyMicroFinance Demo (Pty) Ltd grants you a limited license to access and
        make personal use of the MyMicroFinance Demo (Pty) Ltd website/app. You
        are not allowed to modify (other than page caching) the website/app or
        any portion thereof without the prior written consent of MyMicroFinance
        Demo (Pty) Ltd.
      </p>
      <p>
        This license does not permit any resale or commercial use of the Nora
        Finance (Pty) Ltd website/app or its contents; any collection and use of
        any product listings, descriptions, or prices; any derivative use of the
        MyMicroFinance Demo (Pty) Ltd website/app or its contents; any
        downloading or copying of account information for the benefit of another
        merchant; or any use of data mining, robots, or similar data gathering
        and extraction tools.
      </p>
      <p>
        You are granted a limited, revocable, and nonexclusive right to create a
        hyperlink to the home page of the MyMicroFinance Demo (Pty) Ltd
        website/app so long as the link does not portray MyMicroFinance Demo
        (Pty) Ltd, its affiliates, or their products or services in a false,
        misleading, derogatory, or otherwise offensive matter. You may not use
        any Nora Finance (Pty) Ltd logo or other proprietary graphic or
        trademark, or those of their affiliates, as part of the link without
        prior written permission of the owner of the graphic or trademark.
      </p>
      <p>
        MyMicroFinance Demo (Pty) Ltd may provide links from the MyMicroFinance
        Demo (Pty) Ltd website/app to other website/apps which may offer
        services to you and is not responsible for evaluating the content or
        offerings made on these websites/apps.
      </p>
      <h4>2. DISCLAIMER OF WARRANTIES AND LIMITATION OF LIABILITY</h4>
      <p>
        You expressly agree that your use of MyMicroFinance Demo (Pty) Ltd
        websites/apps is at your sole risk.
      </p>
      <p>
        To the full extent permissible by South African law, MyMicroFinance Demo
        (Pty) Ltd disclaims all warranties, express or implied, including, but
        not limited to, implied warranties of merchantability and fitness for a
        particular purpose. MyMicroFinance Demo (Pty) Ltd will not be liable for
        any damages of any kind arising from the use of the MyMicroFinance Demo
        (Pty) Ltd websites/apps, including, but not limited to direct, inirect,
        incidental, punitive, and consequential damages.
      </p>
      <p>
        All warranty on products are strictly compliant with the Consumer
        Protection Act. Should an additional manufacturer&apos;s warranty be
        afforded on the product, such product will be returned at our cost to
        the end-user, if found in compliance with the terms of such warranty.
      </p>
      <p>
        <strong>Applicable law</strong>
      </p>
      <p>
        The MyMicroFinance Demo (Pty) Ltd website/app and all communications and
        transactions concluded using the website/app, are governed by, and you
        agree to, the application of the laws of the Republic of South Africa.
        As the website/app is targeted at South African citizens and residents
        any limitations on implied warranties or the exclusion or limitation of
        certain damages which may be applicable under the laws of any other
        jurisdiction are expressly waived by you.
      </p>
      <h4>3. TERMS OF TRADE WITH MyMicroFinance Demo (Pty) Ltd</h4>
      <p>
        <strong>Invitation to do business</strong>
      </p>
      <p>
        Only when MyMicroFinance Demo (Pty) Ltd has accepted your offer to
        purchase and the acceptance is communicated to you, will an enforceable
        agreement of sale be transacted. Despite our best efforts, a small
        number of the products or services offered on the website/app may be
        mis-priced. If we discover a mis-pricing, we will do one of the
        following:
      </p>
      <ul>
        <li>
          If the correct price is lower than our stated price, we will charge
          the lower amount.
        </li>
        <li>
          If the correct price is higher than our stated price, we will, at our
          discretion, either contact you for instructions before accepting your
          order or notify you that the order has not been accepted.
        </li>
      </ul>
      <p>
        <strong>IMPORTANT</strong>: If any products or services are supplied and
        delivered to you directly by, or through, a third party, even though
        access to the products and services is gained through the MyMicroFinance
        Demo (Pty) Ltd website/app, the supply of these products and services
        may be subject to conditions which differ from those governing the terms
        of trade with MyMicroFinance Demo (Pty) Ltd . You shall be solely
        responsible for ensuring that you understand and agree to the terms and
        conditions governing the sale of these products and services. Place and
        time of agreement. Agreement as to the terms of use of the
        MyMicroFinance Demo (Pty) Ltd website/app shall be deemed to have be
        accepted by you at the time that you first accessed the MyMicroFinance
        Demo (Pty) Ltd website/app.
      </p>
      <p>
        <strong>Backorders</strong>
      </p>
      <p>
        Due to the popularity of some products or services, MyMicroFinance Demo
        (Pty) Ltd may not always have any products or services ordered by you in
        stock. MyMicroFinance Demo (Pty) Ltd will accept orders for out of stock
        products or services to ensure that you will be the first to receive the
        item once it becomes available. If the products and services ordered by
        you are not available or only a portion is available, you may cancel the
        order, or re-order the quantity that is available, or place a backorder.
        Once the products or services become available, it will be shipped to
        you.
      </p>
      <p>
        <strong>Electronic communications</strong>
      </p>
      <p>
        When you visit MyMicroFinance Demo (Pty) Ltd or send e-mails to us, you
        are communicating with us electronically. MyMicroFinance Demo (Pty) Ltd
        will communicate with you by e-mail or by posting notices on the
        MyMicroFinance Demo (Pty) Ltd website/app. You agree that all
        agreements, notices, disclosures and other communications that we
        provide to you electronically satisfy any legal requirement that such
        communications be in writing.
      </p>
      <p>
        <strong>Registration</strong>
      </p>
      <p>
        Registration as a purchaser is restricted to individuals who may legally
        enter into binding and enforceable agreements. No person under the age
        of 18, or not legally permitted to enter into a binding and enforceable
        agreement, may register as a purchaser. Any person not permitted to
        register as a purchaser but who does so, shall be guilty of committing a
        fraud and MyMicroFinance Demo (Pty) Ltd may take whatever legal action
        it deems necessary to obtain the appropriate relief.
      </p>
      <p>
        In registering as a purchaser you are required to provide MyMicroFinance
        Demo (Pty) Ltd with, complete and accurate information about yourself
        and you warrant that the information provided is correct.
      </p>
      <p>
        If you are in breach of, or MyMicroFinance Demo (Pty) Ltd believes that
        you may breach, any of the terms and conditions governing the use of, or
        trading using the MyMicroFinance Demo (Pty) Ltd website/app,
        MyMicroFinance Demo (Pty) Ltd may, in its absolute discretion, either
        suspend or terminate your registration as a purchaser.
      </p>
      <p>
        <strong>Password</strong>
      </p>
      <p>
        You are solely responsible for ensuring the confidentiality of your
        password, which authenticates your identity. You acknowledge this
        responsibility and agree that any communication or transaction that may
        be made using your password, shall be attributed to you.
      </p>
      <p>
        If you suspect that the confidentiality of your password has been
        compromised, you should immediately advise MyMicroFinance Demo (Pty) Ltd
        of this fact by email. MyMicroFinance Demo (Pty) Ltd shall on receipt of
        the email suspend your registration pending your further instructions.
        You shall remain responsible for all transactions effected prior to the
        suspension of your registration and liable for any payments that may
        flow from these transactions.
      </p>
      <h4>4. AMENDMENT TO TERMS AND CONDITIONS</h4>
      <p>
        MyMicroFinance Demo (Pty) Ltd may amend the terms and conditions
        governing access to the MyMicroFinance Demo (Pty) Ltd website/app as
        well as trade using the website/app.
      </p>
      <h4>5. PRIVACY AND SECURITY STATEMENT</h4>
      <p>
        MyMicroFinance Demo (Pty) Ltd knows that you care how your personal
        information may be collected, processed and used; we appreciate your
        trust that Nora Finance (Pty) Ltd will do so carefully and sensibly.
      </p>
      <p>
        The information provided by you helps us personalize and continually
        improve your shopping experience at MyMicroFinance Demo (Pty) Ltd. Here
        are the types of information we gather.
      </p>
      <ul>
        <li>
          <span className="underline">Automatic Information</span>:
          MyMicroFinance Demo (Pty) Ltd receives and stores certain types of
          information whenever you interact on the MyMicroFinance Demo (Pty) Ltd
          website/app or communicate with MyMicroFinance Demo (Pty) Ltd by
          email. For example, like many Web sites, we use &quot;cookies&quot;,
          and we obtain certain types of information when your web browser
          accesses MyMicroFinance Demo (Pty) Ltd.
        </li>
        <li>
          <span className="underline">Information You Give Us</span>: We receive
          and store information you enter on the MyMicroFinance Demo (Pty) Ltd
          website/app or that you may provide to MyMicroFinance Demo (Pty) Ltd
          in any other manner. Certain of the information provided on
          registration is mandatory to assist us in ensuring that the
          communications and transactions entered into with you may be completed
          effectively and lawfully.
        </li>
        <li>
          <span className="underline">E-mail Communications</span>: To help us
          make e-mails more useful, we may receive a confirmation when you open
          e-mail from MyMicroFinance Demo (Pty) Ltd, if your computer supports
          such capabilities.
        </li>
      </ul>
      <p>
        By providing the information to MyMicroFinance Demo (Pty) Ltd you
        consent to MyMicroFinance Demo (Pty) Ltd collecting maintaining, storing
        and processing the information, provided that MyMicroFinance Demo (Pty)
        Ltd shall:
      </p>
      <ul>
        <li>
          not disclose your personal information to a third party other than to
          effect the transactions agreed to in using the MyMicroFinance Demo
          (Pty) Ltd website/app without obtaining your express consent to do so;
        </li>
        <li>
          only disclose your personal information, without your consent, if
          legally required to do so;
        </li>
        <li>
          be entitled to use the information provided by you to compile
          statistical or aggregated information in such a way that you may not
          be identified.
        </li>
      </ul>
      <p>
        While MyMicroFinance Demo (Pty) Ltd and its affiliates shall take
        commercially reasonable care to safeguard the information provided by
        you to Nora Finance (Pty) Ltd from unauthorized access or disclosure,
        MyMicroFinance Demo (Pty) Ltd does not represent or guarantee that the
        safeguards will provide absolute protection.
      </p>
      <h4>6. INTELLECTUAL PROPERTY STATEMENT</h4>
      <p>
        Copyright in any information or trademarks displayed anywhere on the
        MyMicroFinance Demo (Pty) Ltd website/app are protected by worldwide
        copyright laws and treaty provisions. The copyright of these materials
        is held by MyMicroFinance Demo (Pty) Ltd, or by the original creator of
        the materials, or by suppliers and service providers to MyMicroFinance
        Demo (Pty) Ltd.
      </p>
      <h4>7. DISPUTE RESOLUTION</h4>
      <p>
        Any dispute arising out of or in connection with this agreement
        including any question regarding its existence, validity or termination,
        will be resolved in accordance with the law of South Africa. The
        language used during the dispute resolution process will be English. For
        the purposes of any arbitration:
      </p>
      <ul>
        <li>
          you choose the email address chosen by you in registering as a
          purchaser; and
        </li>
        <li>
          MyMicroFinance Demo (Pty) Ltd chooses{" "}
          <a href="mailto:info@mybusinessapp.co.za">info@mybusinessapp.co.za</a>
        </li>
      </ul>
      <p>
        By using the MyMicroFinance Demo (Pty) Ltd website/app and accepting the
        conditions of use of the website/app and trade conducted using the
        website/app you bind yourself to the terms of arbitration provided in
        this agreement.
      </p>
      <h4>8. COMPLIANCE WITH STATUTORY CONSUMER PROTECTION PROVISIONS</h4>
      <p>
        MyMicroFinance Demo (Pty) Ltd adheres to the provisions of Chapter VII
        of the Electronic Communications and Transactions Act 25 of 2002 (“the
        Act”). Chapter VII of the Act provides protection to consumers and the
        provisions of the Act relevant to commercial transactions that may be
        effected on the MyMicroFinance Demo (Pty) Ltd website/app, as well as
        the information required in terms of the Act, are provided for your
        reference.
      </p>
      <p>
        <strong>
          Return of products or cancellation of service agreements
        </strong>
      </p>
      <p>
        MyMicroFinance Demo (Pty) Ltd shall, subject to the exclusions provided
        for below and the provisions relating to any return of products or
        cancellation of a service agreement, allow the return of products or
        cancellation of a service agreement, within seven days of the date of
        receipt by you of the products or the conclusion of an agreement to
        provide services. Exclusions from return of products or cancellation of
        service agreements. The provisions of this agreement relating to the
        return of goods and cancellation of any agreement to provide services
        shall not apply if:
      </p>
      <ul>
        <li>any products and services are acquired by auction;</li>
        <li>
          to services which commenced with the customer&apos;s consent, (either
          express or implied) prior to the expiry of the seven-day period;
        </li>
        <li>
          where the price of the products or services depends on fluctuation in
          financial markets beyond the control of MyMicroFinance Demo (Pty) Ltd;
        </li>
        <li>
          where the products are personalised or made to the customer&apos;s
          specifications;
        </li>
        <li>
          where the products by reason of their nature cannot be returned;
        </li>
        <li>
          if audio, video recordings or any form of computer software are either
          used or if supplied other than in digital form, if the seal on the
          storage media is broken;
        </li>
        <li>the sale of reading material in whatever form.</li>
      </ul>
      <p>
        <strong>
          Customers obligations on return of products and services
        </strong>
      </p>
      <p>
        If a customer cancels any transaction in respect of which delivery of
        the products or services (to the extent that the may be returned) have
        been delivered, within seven days of the receipt of the products or
        services, the customer shall return to MyMicroFinance Demo (Pty) Ltd or
        its authorised agents, the products, undamaged in any manner, together
        with all packing materials and documentation delivered by MyMicroFinance
        Demo (Pty) Ltd or its agents, to the customer as well as a written
        indication of the customer&apos;s choice to cancel the transaction. The
        customer shall refund to MyMicroFinance Demo (Pty) Ltd all direct
        expenses incurred by Nora Finance (Pty) Ltd or its authorised agents, in
        the delivery of the products and services ordered by the customer, as
        well as the direct expenses incurred by MyMicroFinance Demo (Pty) Ltd or
        its authorised agents in accepting return of the products and
        cancellation of the provision of services, arising as a result of the
        return of the products or cancellation of the services ordered by the
        customer.
      </p>
      <p>
        <strong>
          MyMicroFinance Demo (Pty) Ltd&apos;s obligations on return of the
          products and services
        </strong>
      </p>
      <p>
        Within thirty days of return of the products or receipt of notice of
        cancellation of the agreement to deliver services MyMicroFinance Demo
        (Pty) Ltd or its authorised agents shall refund to the customer any
        amounts paid by the customer less any amounts due by the customer to
        MyMicroFinance Demo (Pty) Ltd as provided for above.
      </p>
      <p>
        Disclosures in terms of section 43 of the Act: The following information
        is made available to you in compliance with Section 43 of the Act.
      </p>
      <p>
        Full name and legal status of website/app owner: MyMicroFinance Demo is
        the trading name and is wholly owned by MyMicroFinance Demo (Pty) Ltd, a
        company duly incorporated in terms of the company laws of South Africa.
      </p>
      <p>
        <span className="underline">Self-regulatory or accreditation body</span>
        : none.
      </p>
      <p>
        <span className="underline">
          Address for service of all legal documents
        </span>
        : none.
      </p>
      <h4>NOTE: Warranty turnaround time, minimum 14 days.</h4>
      <p>
        <span className="underline">Description of Services</span>:
        MyMicroFinance Demo (Pty) Ltd provides access to a variety of products
        and services that may be purchased online all of which are, or will be,
        described on the Nora Finance (Pty) Ltd website/app.
      </p>
      <p>
        <span className="underline">Price of goods</span>: Prices are displayed
        in the website/app and are subject to MyMicroFinance Demo (Pty) Ltd
        rights in respect of any mis-pricing stipulated in the clause dealing
        with Invitation to do business above. All prices of products and
        services displayed on the MyMicroFinance Demo (Pty) Ltd website/app are
        inclusive of Value Added Tax.
      </p>
      <p>
        <span className="underline">Payment</span>: Payment terms and manner of
        payment are stipulated in the clause dealing with payment above.
      </p>
      <p>
        <span className="underline">
          Terms and conditions governing this agreement
        </span>
        : The full Terms and Conditions applicable to the access and use of the
        services provided on the MyMicroFinance Demo (Pty) Ltd website/app and
        any amendments thereto, shall at all times be available on the
        Website/app, save to the extent that they may be varied in writing by
        the parties. These terms may be accessed on this Website/app, downloaded
        and stored, or reproduced at any time.
      </p>
      <p>
        <span className="underline">Delivery of products or services</span>: The
        anticipated time of delivery of any products or services, purchase using
        the MyMicroFinance Demo (Pty) Ltd website/app shall be indicated at the
        time that any order is accepted by MyMicroFinance Demo (Pty) Ltd
      </p>
      <p>
        <span className="underline">Transaction records and history</span>: A
        full record of transactions effected using MyMicroFinance Demo (Pty) Ltd
        shall be available to the user for a period of 14 days from delivery of
        the products or services to the purchaser by MyMicroFinance Demo (Pty)
        Ltd or its agents.
      </p>
      <p>
        <span className="underline">Dispute resolution</span>: The provisions
        governing Dispute Resolution are stipulated under the clause Dispute
        Resolution above.
      </p>
      <p>
        <span className="underline">Privacy and Security</span>: The provisions
        governing Privacy and Security are provided for under the clause Privacy
        and Security above.
      </p>
      <p>
        <span className="underline">Minimum duration of Agreement</span>: There
        shall be no minimum duration of agreements entered into between users
        and registered purchasers to use the MyMicroFinance Demo (Pty) Ltd
        website/app. All agreements shall endure indefinitely subject to either
        party&apos;s rights to terminate the agreement. The termination of the
        agreement by either party shall be without prejudice to any rights
        between the parties existing at the time of the termination, which shall
        endure beyond the date of termination.
      </p>
    </article>
  );
};

export default TermsArticle;
