import { FolderOpenIcon } from "@heroicons/react/24/solid";
import React from "react";
import { ClaimModel } from "../../core/models/claim";
import EmptyState from "../shared/EmptyState";
import LoadingState from "../shared/LoadingState";
import Spinner from "../shared/Spinner";
import ClaimListItem from "./ClaimListItem";
import LoadMoreListItem from "./LoadMoreListItem";

export interface ClaimListProps {
  claims: ClaimModel[];
  isLoading: boolean;
  hasNext: boolean;
  onLoadMoreClick: () => void;
  emptyStateActions?: React.ReactNode;
  nonEmptyStateActions?: React.ReactNode;
  isAdmin?: boolean;
}

const ClaimList: React.FC<ClaimListProps> = (props) => {
  const { claims, isLoading, hasNext, onLoadMoreClick } = props;
  const claimsExist = claims.length > 0;

  return (
    <div className="grid grid-cols-1 gap-4">
      {claimsExist && (
        <React.Fragment>
          {props.nonEmptyStateActions && (
            <div className="mb-4">{props.nonEmptyStateActions}</div>
          )}
          {claims.map((claim) => {
            return (
              <div className="rounded-md border-2 transition-colors hover:bg-indigo-100">
                <ClaimListItem
                  to={`/applications/claims/${claim.id}`}
                  claim={claim}
                />
              </div>
            );
          })}
          {!isLoading && (
            <div className="mt-4">
              <LoadMoreListItem
                hasNext={hasNext}
                onLoadMoreClick={onLoadMoreClick}
                loadMoreLabel="Load Claim Applications"
                noMoreLabel="There are no more claim applications to load"
              />
            </div>
          )}
        </React.Fragment>
      )}

      {!claimsExist && !isLoading && (
        <div className="mt-6 rounded-lg border-2 border-dashed border-gray-300 p-4 md:p-6 lg:p-8">
          <EmptyState
            title="No Claim Applications"
            description="Once created, you will see claim applications show up here."
            icon={(props) => (
              <FolderOpenIcon
                className="mx-auto my-8 h-12 w-12 text-gray-400"
                {...props}
              />
            )}
            actions={props.emptyStateActions}
          />
        </div>
      )}

      {isLoading && (
        <div className="mt-6 rounded-lg border-2 border-dashed border-gray-300 p-4 md:p-6 lg:p-8">
          <LoadingState
            title="Loading Claim Applications"
            description="Loading claim applications, please wait."
          >
            <Spinner textColor="text-indigo-800" />
          </LoadingState>
        </div>
      )}
    </div>
  );
};

export default ClaimList;
