import { Tab } from "@headlessui/react";
import { XCircleIcon } from "@heroicons/react/24/outline";
import { FirebaseError } from "firebase/app";
import { DocumentData, DocumentReference } from "firebase/firestore";
import { navigate } from "gatsby";
import * as React from "react";
import { Id, toast, TypeOptions } from "react-toastify";
import ClaimDetails from "../../../components/details/ClaimDetails";
import CheckField from "../../../components/fields/CheckField";
import ClaimForm from "../../../components/forms/ClaimForm";
import UploadCard from "../../../components/shared/cards/UploadCard";
import {
  useAuthUser,
  useAuthUserDoc,
  useFirebaseService,
} from "../../../core/contexts/firebase";
import { useClaims } from "../../../core/contexts/firebase/claims";
import {
  ApplicationStatus,
  ClaimType,
  UploadErrorCode,
} from "../../../core/enums";
import useClaimFormReducer, {
  ClaimFormActionType,
} from "../../../core/hooks/useClaimFormReducer";
import useClaimSubmissionReducer, {
  ClaimSubmissionActionType,
} from "../../../core/hooks/useClaimSubmissionReducer";
import useUploadFormReducer, {
  UploadFormActionType,
} from "../../../core/hooks/useUploadReducer";
import { ClaimModel } from "../../../core/models/claim";
import { StatusModel } from "../../../core/models/status";
import { linkClassNames } from "../../../core/ui/classNames";
import { handleUpload, UploadError } from "../../../core/utils/uploader";

const steps = [
  { id: 1, name: "Claim details" },
  { id: 2, name: "Upload documents" },
  { id: 3, name: "Review" },
];

const ClaimApplicationPage = () => {
  const user = useAuthUser();
  const userDoc = useAuthUserDoc();
  const firebaseService = useFirebaseService();
  const [i1, i2, i3, getNewClaim] = useClaims();
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const [reviewed, setReviewed] = React.useState(false);

  // Initialise state management for forms
  const [submitter, submitterDispatch] = useClaimSubmissionReducer();
  const [claim, claimDispatch] = useClaimFormReducer();
  const [claimForm, claimFormDispatch] = useUploadFormReducer();

  // Derived change state
  const hasClaimChanged = claim.hasChanged;
  const hasFormChanged = hasClaimChanged;

  // we need to keep a reference of the document reference to prevent the creation of
  // multiple documents. This is needed because the onSaveBusinessLoan function
  // can be called multiple times and the document reference is created in that function.
  const docRef = React.useRef<DocumentReference<DocumentData> | null>(null);

  React.useEffect(() => {
    if (user && firebaseService && docRef.current == null) {
      docRef.current = firebaseService.claimDocRef(user.uid);
    }
  }, [user, firebaseService, docRef.current]);

  // we need to reset the percantages in various cases such as when a user enters or leaves the page
  // and also when an error occurs during file uploads.
  const resetPercents = () =>
    submitterDispatch({
      type: ClaimSubmissionActionType.RESET_ALL_PERCENTS,
    });
  React.useEffect(() => {
    resetPercents();
    return resetPercents;
  }, []);

  // Handle field changes through state management mechanism
  const onSubmitterFieldChanged = (fieldKey: string, fieldValue: any) =>
    submitterDispatch({
      type: ClaimSubmissionActionType.CHANGE_FIELD,
      payload: { fieldKey, fieldValue },
    });
  const onClaimFieldChanged = (fieldKey: string, fieldValue: any) =>
    claimDispatch({
      type: ClaimFormActionType.CHANGE_FIELD,
      payload: { fieldKey, fieldValue },
    });
  const onClaimFormFieldChanged = (fieldKey: string, fieldValue: any) =>
    claimFormDispatch({
      type: UploadFormActionType.CHANGE_FIELD,
      payload: { fieldKey, fieldValue },
    });

  // function helper for bank statement upload
  const handleUploadCF = (claimId: string, uid: string) => {
    const { file } = claimForm.currentData;
    const path = `/users/${uid}/claims/${claimId}/claim_form`;
    return handleUpload(
      path,
      "Claim Form",
      file!,
      firebaseService!,
      (percent) => onSubmitterFieldChanged("percent.claimForm", percent),
      (url) => onSubmitterFieldChanged("download.claimFormUrl", url)
    );
  };

  const handleUploadError = (error: UploadError) => {
    let message =
      "Something went wrong while uploading your claim document, please try again later";
    if (error.code === UploadErrorCode.failed_to_upload) {
      message = `Unable to upload "${error.name}". Please try using a different file. If the problem still persists, please contact support.`;
    } else if (error.code === UploadErrorCode.failed_to_get_download_url) {
      message = `Unable to get download url for  "${error.name}". Please try again.`;
    }
    makeToast(message, toast.TYPE.ERROR, false);
    toastId.current = null;
  };

  // we need to keep a reference of the toastId to be able to update it
  const toastId = React.useRef<Id | null>(null);

  const onSaveClaim = async () => {
    const ref = docRef.current;
    if (userDoc && firebaseService && ref) {
      makeToast("Uploading documents.", toast.TYPE.INFO, false);

      onSubmitterFieldChanged("isSubmitting", true);
      // Save the user's claim information in a new claim document
      const { uid } = userDoc;
      try {
        // Upload the files
        const [setDownloadUrlCF] = handleUploadCF(ref.id, uid);
        const uploads = await Promise.all([setDownloadUrlCF]);

        makeToast("Submitting claim application.", toast.TYPE.INFO, false);
        // Save the claim document
        const user = {
          uid: userDoc.uid,
          name: userDoc.name,
          surname: userDoc.surname,
          email: userDoc.email,
          cell: userDoc.cell,
        };
        const claimModel: ClaimModel = {
          ...claim.currentData,
          id: ref.id,
          status: ApplicationStatus.created,
          user,
          download: {
            claimFormUrl: uploads[0],
          },
          createdAt: "",
          updatedAt: "",
        };
        const status: StatusModel = {
          status: ApplicationStatus.created,
          user,
          id: "",
          applicationId: ref.id,
          createdAt: "",
          updatedAt: "",
        };
        await Promise.all([
          firebaseService.setClaimDoc(uid, claimModel, ref),
          firebaseService.setClaimStatusDoc(uid, ref.id, status),
        ]);
        getNewClaim();
        makeToast(
          "Your claim application has been submitted successfully.",
          toast.TYPE.SUCCESS
        );
        onSubmitterFieldChanged("isSubmitting", false);
        onSubmitterFieldChanged("isSubmitted", true);
        navigate("/applications?tab=claims");
      } catch (error) {
        onSubmitterFieldChanged("isSubmitting", false);
        resetPercents();
        let message =
          "Something went wrong while submitting your claim application, please try again later";
        if (error instanceof UploadError) {
          handleUploadError(error);
        } else if (error instanceof FirebaseError) {
          if (error.code === "invalid-argument") {
            message = `Unable to submit. ${error.message}`;
          }
          makeToast(message, toast.TYPE.ERROR, false);
          toastId.current = null;
        }
      }
    }
  };

  const makeToast = (
    message: string,
    type?: TypeOptions,
    autoClose?: number | false
  ) => {
    // check if we already displayed a toast
    if (toastId.current === null) {
      toastId.current = toast(message, {
        type,
        autoClose,
      });
    } else {
      toast.update(toastId.current, {
        render: message,
        type,
        autoClose,
      });
    }
  };

  const getStepperContainerClassNames = (stepIndex: number) => {
    let classNames =
      "flex flex-col border-l-4 py-2 pl-4 md:border-l-0 md:border-t-4 md:pl-0 md:pt-4 md:pb-0";
    if (stepIndex > selectedIndex) {
      // Incomplete
      classNames = `${classNames} border-gray-200 group`;
    } else if (stepIndex === selectedIndex) {
      // Current
      classNames = `${classNames} border-indigo-600 hover:border-gray-300`;
    } else if (stepIndex < selectedIndex) {
      // Complete
      classNames = `${classNames} border-indigo-600 group hover:border-indigo-800`;
    }
    return classNames;
  };

  const getStepperIdClassNames = (stepIndex: number) => {
    let classNames = "text-sm font-medium";
    if (stepIndex > selectedIndex) {
      // Incomplete
      classNames = `${classNames} text-gray-500 group-hover:text-gray-700`;
    } else if (stepIndex === selectedIndex) {
      // Current
      classNames = `${classNames} text-indigo-600`;
    } else if (stepIndex < selectedIndex) {
      // Complete
      classNames = `${classNames} text-indigo-600 group hover:text-indigo-800`;
    }
    return classNames;
  };

  const goToPreviousTab = () => setSelectedIndex(selectedIndex - 1);
  const goToNextTab = () => setSelectedIndex(selectedIndex + 1);

  return (
    <React.Fragment>
      <div className="mx-auto mt-16 px-4 sm:px-6 lg:max-w-6xl lg:px-8">
        <div className="flex items-center justify-between gap-8">
          <h3 className="text-lg font-medium leading-6 text-gray-900 lg:text-2xl">
            New Claim
          </h3>
          <div className="flex gap-4">
            <div
              className={`text-sm transition-opacity duration-300 ${
                selectedIndex != 0 ? "opacity-1" : "opacity-0"
              }`}
            >
              <button
                onClick={goToPreviousTab}
                disabled={selectedIndex === 0}
                className="inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2"
              >
                &larr;&nbsp;Previous Step
              </button>
            </div>
            <button
              type="button"
              onClick={() => {
                if (hasFormChanged) {
                  // TODO: Ask user if they are sure because there are unsaved changes
                  navigate(-1);
                } else {
                  navigate(-1);
                }
              }}
              className="inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2"
            >
              <XCircleIcon className="-ml-1 mr-2 h-5 w-5 text-gray-600" />
              Cancel
            </button>
          </div>
        </div>
        <Tab.Group selectedIndex={selectedIndex}>
          <Tab.List className="mt-12 space-y-4 rounded-md bg-white px-4 py-4 shadow-md md:flex md:space-y-0 md:space-x-8 md:px-6 lg:px-8">
            {steps.map((step) => (
              <Tab
                key={step.id}
                className={getStepperContainerClassNames(step.id - 1)}
              >
                <span className={getStepperIdClassNames(step.id - 1)}>
                  Step {step.id}
                </span>
                <span className="text-start text-sm font-medium">
                  {step.name}
                </span>
              </Tab>
            ))}
          </Tab.List>
          <Tab.Panels>
            <Tab.Panel tabIndex={0}>
              <p className="mt-12 mb-8 text-base text-gray-500">
                Please enter your claim details.
              </p>
              <ClaimForm
                label="Next"
                claim={claim.currentData}
                onFieldChanged={onClaimFieldChanged}
                onButtonClick={(isValid) => isValid && goToNextTab()}
              />
            </Tab.Panel>
            <Tab.Panel tabIndex={1}>
              <p className="mt-12 mb-8 text-base text-gray-500">
                Please upload a copy of your claim form. If you need a copy of
                the claim form, please{" "}
                <a
                  href="../../Nora_Finance_Funeral_Claim_Form_v1.pdf"
                  target="_blank"
                  download
                  rel="noopener noreferrer"
                  className={linkClassNames}
                >
                  download the form
                </a>
                .
              </p>
              <div className="grid grid-cols-1 gap-y-8">
                <div className="col-span-1">
                  <UploadCard
                    label="1. Upload Claim Form"
                    description="You can only upload a PDF, JPG, or PNG file of up to 5MB"
                    file={claimForm.currentData.file}
                    onChange={(f) => onClaimFormFieldChanged("file", f)}
                  />
                </div>
                <button
                  type={"button"}
                  onClick={() => {
                    const isClaimFormValid = claimForm.currentData.file != null;

                    if (isClaimFormValid) {
                      goToNextTab();
                    } else {
                      toast.warn("Please upload document before you continue.");
                    }
                  }}
                  className="col-span-1 flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 md:col-span-2 lg:col-span-3"
                >
                  Next
                </button>
              </div>
            </Tab.Panel>
            <Tab.Panel tabIndex={2}>
              <p className="mt-12 mb-8 text-base text-gray-500">
                Review your claim details and uploaded documents before
                submitting your claim application.
              </p>
              <p className="col-span-2 mt-20 text-2xl font-medium">
                1. Claim details
              </p>
              <div className="mt-6 rounded-md border-2 p-4 shadow-md">
                <ClaimDetails claim={claim.currentData} />
              </div>
              <p className="col-span-2 mt-20 text-2xl font-medium">
                2. Upload documents
              </p>
              <div className="mt-6 rounded-md border-2 p-4 shadow-md">
                <div className="grid grid-cols-1 gap-y-4">
                  <UploadCard
                    label="Claim Form"
                    description={`${submitter.percent.claimForm}% Uploaded`}
                    file={claimForm.currentData.file!}
                  />
                </div>
              </div>
              <p className="col-span-2 mt-20 text-2xl font-medium">
                3. Review & Submit
              </p>
              <p className="col-span-2 mt-4">
                <CheckField
                  id="claim-application-user-reviewed"
                  checked={reviewed}
                  description={
                    'By clicking "Submit Application" you agree that the claim details and uploaded documents are correct and current.'
                  }
                  onChange={setReviewed}
                />
              </p>
              <button
                type={"button"}
                disabled={submitter.isSubmitting}
                onClick={() => {
                  if (reviewed) {
                    // Submit document
                    onSaveClaim();
                  } else {
                    toast.warn(
                      "Please ensure that you have reviewed the application and checked the relevant checkbox"
                    );
                  }
                }}
                className="col-span-1 mt-12 flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 md:col-span-2 lg:col-span-3"
              >
                Submit Application
              </button>
            </Tab.Panel>
          </Tab.Panels>
        </Tab.Group>
      </div>
    </React.Fragment>
  );
};

export default ClaimApplicationPage;
