import {
  collection,
  collectionGroup,
  DocumentData,
  getDocs,
  limit,
  orderBy,
  Query,
  query,
  QueryDocumentSnapshot,
  startAfter,
  where,
} from "firebase/firestore";
import * as React from "react";
import { StatusModel } from "../../../models/status";
import { PaginationMetadata } from "../../../models/pagination-metadata";
import { useAuthUser, useAuthIsConnected, useFirebaseService } from "..";

type ContextState = {
  businessLoanStatuses: StatusModel[];
  paginationMetadataForBusinessLoans: PaginationMetadata;
  getNextBusinessLoanStatuses: (
    id: string,
    userOnly: boolean,
    refresh: boolean
  ) => Promise<void>;
  getNewBusinessLoanStatus: () => Promise<void>;
};

const initialState: ContextState = {
  businessLoanStatuses: [],
  paginationMetadataForBusinessLoans: {
    hitsPerPage: 25,
    hasNext: true,
  },
  getNextBusinessLoanStatuses: () => Promise.resolve(),
  getNewBusinessLoanStatus: () => Promise.resolve(),
};

export const BusinessLoanStatusesContext =
  React.createContext<ContextState>(initialState);

export type BusinessLoanStatusesContextProviderProps = {
  children: React.ReactNode;
};

export const BusinessLoanStatusesContextProvider = ({
  children,
}: BusinessLoanStatusesContextProviderProps) => {
  const user = useAuthUser();
  const isConnected = useAuthIsConnected();
  const firebase = useFirebaseService();
  const [lastVisible, setLastVisible] =
    React.useState<QueryDocumentSnapshot<DocumentData>>();
  const [businessLoanId, setBusinessLoanId] = React.useState("");
  const [businessLoanStatuses, setBusinessLoanStatuses] = React.useState<
    StatusModel[]
  >(initialState.businessLoanStatuses);
  const [paginationMetadataForBusinessLoans, setPaginationMetadata] =
    React.useState<PaginationMetadata>(
      initialState.paginationMetadataForBusinessLoans
    );

  const getNextBusinessLoanStatuses = async (
    id: string,
    userOnly: boolean,
    refresh: boolean
  ): Promise<void> => {
    const { hitsPerPage: pageLimit, hasNext } =
      paginationMetadataForBusinessLoans;
    if (!refresh && !hasNext) return;
    if (isConnected && user && firebase) {
      let ref: Query<DocumentData>;
      userOnly = userOnly != undefined && userOnly;
      const wrapWithQuery = () =>
        (ref = query(ref, orderBy("createdAt", "desc"), limit(pageLimit)));
      if (userOnly) {
        ref = collection(
          firebase.db,
          "tenants",
          firebase.tenantId,
          "users",
          user.uid,
          "business_loans",
          id,
          "statuses"
        );
        wrapWithQuery();
        if (!refresh && lastVisible) ref = query(ref, startAfter(lastVisible));
      } else {
        ref = collectionGroup(firebase.db, "statuses");
        wrapWithQuery();
        ref = query(ref, where("applicationId", "==", id));
        if (!refresh && lastVisible) ref = query(ref, startAfter(lastVisible));
      }
      const data = await getDocs(ref);

      const nextBatch = data.docs.map((doc) => doc.data() as StatusModel);
      const nextBatchLast = nextBatch[nextBatch.length - 1];
      const last = businessLoanStatuses[businessLoanStatuses.length - 1];
      if (refresh) {
        setBusinessLoanId(id);
        setBusinessLoanStatuses([...nextBatch]);
        setLastVisible(data.docs[data.docs.length - 1]);
        setPaginationMetadata({
          ...paginationMetadataForBusinessLoans,
          hasNext: data.size === pageLimit,
        });
      } else if (nextBatchLast !== last) {
        setBusinessLoanId(id);
        setBusinessLoanStatuses([...businessLoanStatuses, ...nextBatch]);
        setLastVisible(data.docs[data.docs.length - 1]);
        setPaginationMetadata({
          ...paginationMetadataForBusinessLoans,
          hasNext: data.size === pageLimit,
        });
      }
    } else {
      return Promise.reject("firebase service is undefined");
    }
  };

  const getNewBusinessLoanStatus = async () => {
    if (isConnected && firebase && user) {
      const ref = collection(
        firebase.db,
        "tenants",
        firebase.tenantId,
        "users",
        user.uid,
        "business_loans",
        businessLoanId,
        "statuses"
      );
      const q = query(ref, orderBy("createdAt", "desc"), limit(1));
      const data = await getDocs(q);
      if (data.size === 1) {
        const oldStatus = businessLoanStatuses[0];
        const newStatus = data.docs[0].data() as StatusModel;
        if (newStatus.id !== oldStatus?.id) {
          setBusinessLoanStatuses([newStatus, ...businessLoanStatuses]);
        }
      }
    }
  };

  return (
    <BusinessLoanStatusesContext.Provider
      value={{
        businessLoanStatuses,
        paginationMetadataForBusinessLoans,
        getNextBusinessLoanStatuses,
        getNewBusinessLoanStatus,
      }}
    >
      {children}
    </BusinessLoanStatusesContext.Provider>
  );
};

export const useBusinessLoanStatuses = (): [
  StatusModel[],
  PaginationMetadata,
  (id: string, userOnly: boolean, refresh: boolean) => Promise<void>,
  () => Promise<void>
] => {
  const context = React.useContext(BusinessLoanStatusesContext);
  if (context === undefined) {
    throw new Error(
      "useBusinessLoanStatuses must be used within a BusinessLoanStatusesProvider"
    );
  }
  return [
    context.businessLoanStatuses,
    context.paginationMetadataForBusinessLoans,
    context.getNextBusinessLoanStatuses,
    context.getNewBusinessLoanStatus,
  ];
};
