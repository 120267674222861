import { FolderOpenIcon } from "@heroicons/react/24/solid";
import React from "react";
import { InsuranceModel } from "../../core/models/insurance";
import EmptyState from "../shared/EmptyState";
import LoadingState from "../shared/LoadingState";
import Spinner from "../shared/Spinner";
import InsuranceListItem from "./InsuranceListItem";
import LoadMoreListItem from "./LoadMoreListItem";

export interface InsuranceListProps {
  insurances: InsuranceModel[];
  isLoading: boolean;
  hasNext: boolean;
  onLoadMoreClick: () => void;
  emptyStateActions?: React.ReactNode;
  nonEmptyStateActions?: React.ReactNode;
  isAdmin?: boolean;
}

const InsuranceList: React.FC<InsuranceListProps> = (props) => {
  const { insurances, isLoading, hasNext, onLoadMoreClick } = props;
  const insuranceExist = insurances.length > 0;

  return (
    <div className="grid grid-cols-1 gap-4">
      {insuranceExist && (
        <React.Fragment>
          {props.nonEmptyStateActions && (
            <div className="mb-4">{props.nonEmptyStateActions}</div>
          )}
          {insurances.map((insurance) => {
            return (
              <div className="rounded-md border-2 transition-colors hover:bg-indigo-100">
                <InsuranceListItem
                  to={`/applications/insurances/${insurance.id}`}
                  insurance={insurance}
                />
              </div>
            );
          })}
          {!isLoading && (
            <div className="mt-4">
              <LoadMoreListItem
                hasNext={hasNext}
                onLoadMoreClick={onLoadMoreClick}
                loadMoreLabel="Load Insurance Applications"
                noMoreLabel="There are no more insurance applications to load"
              />
            </div>
          )}
        </React.Fragment>
      )}

      {!insuranceExist && !isLoading && (
        <div className="mt-6 rounded-lg border-2 border-dashed border-gray-300 p-4 md:p-6 lg:p-8">
          <EmptyState
            title="No Insurance Applications"
            description="Once created, you will see insurance applications show up here."
            icon={(props) => (
              <FolderOpenIcon
                className="mx-auto my-8 h-12 w-12 text-gray-400"
                {...props}
              />
            )}
            actions={props.emptyStateActions}
          />
        </div>
      )}

      {isLoading && (
        <div className="mt-6 rounded-lg border-2 border-dashed border-gray-300 p-4 md:p-6 lg:p-8">
          <LoadingState
            title="Loading Insurance Applications"
            description="Loading insurance applications, please wait."
          >
            <Spinner textColor="text-indigo-800" />
          </LoadingState>
        </div>
      )}
    </div>
  );
};

export default InsuranceList;
