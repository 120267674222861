import * as React from "react";
import CurrencyInput from "react-currency-input-field";

export interface CurrencyFieldProps {
  label: string;
  id?: string;
  value?: string;
  autoComplete?: string;
  prefix?: string;
  step?: number;
  decimalsLimit?: number;
  decimalScale?: number;
  min?: number;
  max?: number;
  required?: boolean;
  onChange: (value: string | undefined) => void;
}

const CurrencyField = ({
  label,
  id = "number",
  autoComplete = "number",
  value,
  prefix,
  step = 50,
  decimalsLimit = 2,
  decimalScale = 2,
  min = 0,
  max,
  required,
  onChange,
}: CurrencyFieldProps) => {
  return (
    <div className="space-y-1">
      <label htmlFor={id} className="block text-sm font-medium text-gray-700">
        {label}
      </label>
      <CurrencyInput
        id={id}
        name={id}
        autoComplete={autoComplete}
        min={min}
        max={max}
        step={step}
        prefix={prefix}
        value={value ? value : min}
        decimalsLimit={decimalsLimit}
        decimalScale={decimalScale}
        onValueChange={(value, name) => {
          onChange(value);
        }}
        required={required}
        className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
      />
    </div>
  );
};

export default CurrencyField;
