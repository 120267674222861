import { Combobox, Dialog, Transition } from "@headlessui/react";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import React from "react";
import { useCommandPaletteIsOpen } from "../../core/contexts/ui/command-palette";
import {
  formatAmount,
  formatApplicationStatus,
  formatClaimType,
  formatDate,
  formatAlgoliaDate,
  formatInsuranceCover,
  formatAlgoliaTime,
} from "../../core/utils/format";
import { Link, navigate } from "gatsby";
import Spinner from "./Spinner";
import ElevatedButton from "./buttons/ElevatedButton";
import { useQuickSearch } from "../../core/contexts/search";
import { useAdminMode } from "../../core/contexts/ui/admin-mode";

const CommandPalette = () => {
  const [isAdminMode] = useAdminMode();
  const [isOpen, setIsOpen] = useCommandPaletteIsOpen();
  const [results, search] = useQuickSearch();
  const [isFetching, setIsFetching] = React.useState(false);
  const [query, setQuery] = React.useState("");

  const onFetchQueryResults = async () => {
    setIsFetching(true);
    await search(query);
    setIsFetching(false);
  };

  React.useEffect(() => {
    const onKeydown = (e: KeyboardEvent) => {
      if (isAdminMode && e.key === "k" && (e.metaKey || e.ctrlKey)) {
        setIsOpen(!isOpen);
      }
    };
    window.addEventListener("keydown", onKeydown);
    return () => {
      window.removeEventListener("keydown", onKeydown);
    };
  }, [isAdminMode]);

  return (
    <Transition.Root show={isOpen} as={React.Fragment}>
      <Dialog
        onClose={setIsOpen}
        className="fixed inset-0 z-50 overflow-hidden p-4 pt-[15vh]"
      >
        <Transition.Child
          as={React.Fragment}
          enter="duration-300 ease-out"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="duration-200 ease-in"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Dialog.Overlay className="fixed inset-0 bg-gray-500/75" />
        </Transition.Child>

        <Transition.Child
          as="span"
          enter="duration-300 ease-out"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="duration-200 ease-in"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <Combobox
            onChange={(value) => console.log(value)}
            as="div"
            className="relative mx-auto max-w-2xl space-y-4 rounded-xl bg-gray-100 pb-2 shadow-2xl ring-1 ring-black/5"
          >
            <form
              onClick={(e) => {
                e.preventDefault();
                if (query) {
                  onFetchQueryResults();
                }
              }}
              className="rounded-t-xl bg-gray-100 shadow-md"
            >
              <div className="flex items-center gap-4 p-4">
                <input
                  onChange={(e) => setQuery(e.target.value)}
                  value={query}
                  className="h-12 w-full rounded-md border-2 bg-white px-2 text-base text-gray-800 placeholder-gray-400 focus:ring-0"
                  placeholder="Search..."
                />

                <button
                  type="submit"
                  className="inline-flex h-12 w-12 items-center justify-center rounded-full border border-indigo-200 bg-white px-4 text-black shadow-md hover:bg-slate-200 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                >
                  <MagnifyingGlassIcon className="h-5 w-5" aria-hidden="true" />
                </button>
              </div>
            </form>
            {isFetching ? (
              <div className="flex flex-col items-center justify-center gap-y-2 p-4">
                <Spinner textColor="text-indigo-800" />
                <p>Searching...</p>
              </div>
            ) : (
              <div className="max-h-[450px] space-y-2 divide-y-2 divide-gray-200 overflow-y-auto px-4">
                <div className="space-y-1">
                  <div className="mb-4">
                    <ElevatedButton
                      isOutline
                      isFullWidth
                      onClick={() => {
                        setIsOpen(false);
                        navigate("/admin/search");
                      }}
                    >
                      Try the Advanced Search
                    </ElevatedButton>
                  </div>
                  <p className="pt-4 text-base font-bold text-indigo-800">
                    Applicants
                  </p>
                  {results.users.length > 0 ? (
                    <Combobox.Options static className="py-2">
                      {results.users.map((user) => (
                        <Combobox.Option key={user.uid} value={user.uid}>
                          {({ active }) => (
                            <Link
                              onClick={() => setIsOpen(false)}
                              to={`/admin/applicants/${user.uid}`}
                              className={`cursor-pointer rounded-md p-1 transition-colors ${
                                active ? "bg-indigo-200" : "bg-transparent"
                              }`}
                            >
                              {`${user.surname}, ${user.name} - ${user.email}`}
                            </Link>
                          )}
                        </Combobox.Option>
                      ))}
                    </Combobox.Options>
                  ) : (
                    <p className="py-2 text-sm text-gray-400">No results</p>
                  )}
                </div>
                <div className="space-y-1 pt-4">
                  <p className="text-base font-bold text-indigo-800">
                    Loans - Personal
                  </p>
                  {results.personalLoans.length > 0 ? (
                    <Combobox.Options static className="py-2">
                      {results.personalLoans.map((loan) => (
                        <Combobox.Option key={loan.id} value={loan.id}>
                          {({ active }) => (
                            <Link
                              onClick={() => setIsOpen(false)}
                              to={`/applications/personal-loans/${loan.id}`}
                              className={`cursor-pointer rounded-md p-1 transition-colors ${
                                active ? "bg-indigo-200" : "bg-transparent"
                              }`}
                            >
                              {`${formatAmount(loan.amount)}${
                                loan.user
                                  ? ` by ${loan.user.name} ${loan.user.surname}`
                                  : " by Unknown User"
                              } - Status: ${formatApplicationStatus(
                                loan.status
                              )} - ${formatAlgoliaDate(
                                loan.createdAt
                              )} - ${formatAlgoliaTime(loan.createdAt)}`}
                            </Link>
                          )}
                        </Combobox.Option>
                      ))}
                    </Combobox.Options>
                  ) : (
                    <p className="py-2 text-sm text-gray-400">No results</p>
                  )}
                </div>
                <div className="space-y-1 pt-4">
                  <p className="text-base font-bold text-indigo-800">
                    Loans - Business
                  </p>
                  {results.businessLoans.length > 0 ? (
                    <Combobox.Options static className="py-2">
                      {results.businessLoans.map((loan) => (
                        <Combobox.Option key={loan.id} value={loan.id}>
                          {({ active }) => (
                            <Link
                              onClick={() => setIsOpen(false)}
                              to={`/applications/business-loans/${loan.id}`}
                              className={`cursor-pointer rounded-md p-1 transition-colors ${
                                active ? "bg-indigo-200" : "bg-transparent"
                              }`}
                            >
                              {`${formatAmount(loan.amount)}${
                                loan.user
                                  ? ` by ${loan.user.name} ${loan.user.surname}`
                                  : " by Unknown User"
                              } - Status: ${formatApplicationStatus(
                                loan.status
                              )} - ${formatAlgoliaDate(
                                loan.createdAt
                              )} - ${formatAlgoliaTime(loan.createdAt)}`}
                            </Link>
                          )}
                        </Combobox.Option>
                      ))}
                    </Combobox.Options>
                  ) : (
                    <p className="py-2 text-sm text-gray-400">No results</p>
                  )}
                </div>
                <div className="space-y-1 pt-4">
                  <p className="text-base font-bold text-indigo-800">Claims</p>
                  {results.claims.length > 0 ? (
                    <Combobox.Options static className="py-2">
                      {results.claims.map((claim) => (
                        <Combobox.Option key={claim.id} value={claim.id}>
                          {({ active }) => (
                            <Link
                              onClick={() => setIsOpen(false)}
                              to={`/applications/claims/${claim.id}`}
                              className={`cursor-pointer rounded-md p-1 transition-colors ${
                                active ? "bg-indigo-200" : "bg-transparent"
                              }`}
                            >
                              {`${formatClaimType(claim.type)}${
                                claim.user
                                  ? ` by ${claim.user.name} ${claim.user.surname}`
                                  : " by Unknown User"
                              } - Status: ${formatApplicationStatus(
                                claim.status
                              )} - ${formatAlgoliaDate(
                                claim.createdAt
                              )} - ${formatAlgoliaTime(claim.createdAt)}`}
                            </Link>
                          )}
                        </Combobox.Option>
                      ))}
                    </Combobox.Options>
                  ) : (
                    <p className="py-2 text-sm text-gray-400">No results</p>
                  )}
                </div>
                <div className="space-y-1 pt-4">
                  <p className="text-base font-bold text-indigo-800">
                    Insurances
                  </p>
                  {results.insurances.length > 0 ? (
                    <Combobox.Options static className="py-2">
                      {results.insurances.map((insurance) => (
                        <Combobox.Option
                          key={insurance.id}
                          value={insurance.id}
                        >
                          {({ active }) => (
                            <Link
                              onClick={() => setIsOpen(false)}
                              to={`/applications/insurances/${insurance.id}`}
                              className={`cursor-pointer rounded-md p-1 transition-colors ${
                                active ? "bg-indigo-200" : "bg-transparent"
                              }`}
                            >
                              {`${formatInsuranceCover(insurance.cover)}${
                                insurance.user
                                  ? ` by ${insurance.user.name} ${insurance.user.surname}`
                                  : " by Unknown User"
                              } - Status: ${formatApplicationStatus(
                                insurance.status
                              )} - ${formatAlgoliaDate(
                                insurance.createdAt
                              )} - ${formatAlgoliaTime(insurance.createdAt)}`}
                            </Link>
                          )}
                        </Combobox.Option>
                      ))}
                    </Combobox.Options>
                  ) : (
                    <p className="py-2 text-sm text-gray-400">No results</p>
                  )}
                </div>
              </div>
            )}
          </Combobox>
        </Transition.Child>
      </Dialog>
    </Transition.Root>
  );
};

export default CommandPalette;
