import {
  collection,
  DocumentData,
  getDocs,
  limit,
  orderBy,
  query,
  QueryDocumentSnapshot,
  startAfter,
} from "firebase/firestore";
import * as React from "react";
import { useAuthIsConnected, useAuthUser, useFirebaseService } from ".";
import { PaginationMetadata } from "../../models/pagination-metadata";
import { UserModel } from "../../models/user";

type ContextState = {
  users: UserModel[];
  detailsUser?: UserModel;
  paginationMetadata: PaginationMetadata;
  getNextUsers: () => Promise<void>;
  getUserByUid: (uid: string) => Promise<void>;
};

const initialState: ContextState = {
  users: [],
  paginationMetadata: {
    hitsPerPage: 25,
    hasNext: true,
  },
  getNextUsers: () => Promise.resolve(),
  getUserByUid: (uid: string) => Promise.resolve(),
};

export const UsersContext = React.createContext<ContextState>(initialState);

type UsersContextProviderProps = {
  children: React.ReactNode;
};

const UsersContextProvider = ({ children }: UsersContextProviderProps) => {
  const isConnected = useAuthIsConnected();
  const firebase = useFirebaseService();
  const [lastVisible, setLastVisible] =
    React.useState<QueryDocumentSnapshot<DocumentData>>();
  const [users, setUsers] = React.useState<UserModel[]>(initialState.users);
  const [detailsUser, setDetailUser] = React.useState<UserModel | undefined>(
    initialState.detailsUser
  );
  const [paginationMetadata, setPaginationMetadata] =
    React.useState<PaginationMetadata>(initialState.paginationMetadata);

  const getNextUsers = async (): Promise<void> => {
    const { hitsPerPage: pageLimit } = paginationMetadata;
    if (isConnected && firebase) {
      const ref = collection(
        firebase.db,
        "tenants",
        firebase.tenantId,
        "users"
      );
      let q = query(ref, orderBy("surname", "asc"), limit(pageLimit));
      if (lastVisible) q = query(q, startAfter(lastVisible));
      const data = await getDocs(q);
      const nextBatch = data.docs.map((doc) => doc.data() as UserModel);
      const nextBatchLast = nextBatch[nextBatch.length - 1];
      const usersLast = users[users.length - 1];
      if (nextBatchLast !== usersLast) {
        setUsers([...users, ...nextBatch]);
        setLastVisible(data.docs[data.docs.length - 1]);
        setPaginationMetadata({
          ...paginationMetadata,
          hasNext: data.size === pageLimit,
        });
      }
    } else {
      return Promise.reject("firebase service is undefined");
    }
  };

  const getUserByUid = async (uid: string): Promise<void> => {
    const u = users.find((user) => user.uid === uid);
    if (u) {
      setDetailUser(u);
    } else if (isConnected && firebase) {
      const userDoc = await firebase.userDoc(uid);
      setDetailUser(userDoc.data() as UserModel);
    }
  };

  return (
    <UsersContext.Provider
      value={{
        users,
        detailsUser,
        paginationMetadata,
        getNextUsers,
        getUserByUid,
      }}
    >
      {children}
    </UsersContext.Provider>
  );
};

const useUsers = (): [UserModel[], PaginationMetadata, () => Promise<void>] => {
  const context = React.useContext(UsersContext);
  if (context === undefined) {
    throw new Error("useUsers must be used within a UsersProvider");
  }
  return [context.users, context.paginationMetadata, context.getNextUsers];
};

const useUserByUid = (): [
  UserModel | undefined,
  (uid: string) => Promise<void>
] => {
  const context = React.useContext(UsersContext);
  if (context === undefined) {
    throw new Error("useUserByUid must be used within a UsersProvider");
  }
  return [context.detailsUser, context.getUserByUid];
};

export { UsersContextProvider, useUsers, useUserByUid };
