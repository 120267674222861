import * as React from "react";
import CurrencyField from "../fields/CurrencyField";
import RangeField from "../fields/RangeField";

export interface CreateLoanFormProps {
  label: string;
  amount?: string;
  minLabel?: string;
  maxLabel?: string;
  minimumAmount?: number;
  maximumAmount?: number;
  type?: "submit" | "button";
  onButtonClick: (isValid: boolean) => void;
  onAmountChanged: (amount: string | undefined) => void;
}

const CreateLoanForm = (props: CreateLoanFormProps) => {
  const {
    amount,
    minLabel = "R 500",
    maxLabel = "R 5,000",
    minimumAmount = 500,
    maximumAmount = 5000,
  } = props;

  const submitForm = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    let isFormValid = event.currentTarget.checkValidity();
    const isInRange = amount
      ? parseFloat(amount) >= minimumAmount &&
        parseFloat(amount) <= maximumAmount
      : false;
    props.onButtonClick(isFormValid && isInRange);
  };

  return (
    <form onSubmit={submitForm} className="grid grid-cols-2 gap-6">
      <div className="col-span-2">
        <RangeField
          minLabel={minLabel}
          maxLabel={maxLabel}
          id="create-loan-form-range"
          min={minimumAmount}
          max={maximumAmount}
          step={100}
          value={props.amount}
          onChange={props.onAmountChanged}
        />
      </div>
      <div className="col-span-2">
        <CurrencyField
          label="Loan Amount"
          id="create-loan-form-amount"
          step={100}
          min={minimumAmount}
          max={maximumAmount}
          prefix="R "
          value={props.amount}
          onChange={props.onAmountChanged}
        />
      </div>
      <div className="col-span-2 flex gap-8">
        <button
          type={props.type ?? "submit"}
          className="flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
        >
          {props.label}
        </button>
      </div>
    </form>
  );
};

export default CreateLoanForm;
